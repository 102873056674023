<template>
  <CRow class="justify-content-center">
    <CCol class="w-100 m-5" col="11" md="10" lg="8" xl="6">
      <CCard>
        <CCardHeader>
          <h3>Зворотній зв'язок</h3>
          <ul v-if="alerts.length >= 1" style="list-style-type: none" class="p-0">
            <li v-for="alert in alerts" :key="alert" data-cy="error">
              <CAlert style="font-size: 12px;" show :color="alert.color">{{ alert.text }}</CAlert>
            </li>
          </ul>
        </CCardHeader>
        <CCardBody>
          <CForm :was-validated="wasValidated"
                 @submit.prevent="sendFeedBack"
                 novalidate>
            <CCardHeader>
              Тема звернення
            </CCardHeader>
            <CInput
                required
                placeholder="Напишіть заголовок звернення"
                v-model="title_FeedBack">
            </CInput>
            <CCardHeader>
              Опис
            </CCardHeader>
            <CTextarea
                required
                placeholder="Опишіть вашу проблему чи пропозицію"
                cols="6"
                rows="9"
                v-model="comment_FeedBack"></CTextarea>
            <template v-if="!can('provider_user') || can('bank_user')">
              <CCardHeader>Введіть свій Email</CCardHeader>
              <CInput
                  required
                  placeholder="Email"
                  prepend="@"
                  invalid-feedback="Введіть коректно email"
                  autocomplete="email"
                  v-model="email"
              >
              </CInput>
              <CRow class="mb-2">
                <CCol>
                  <vue-recaptcha
                      ref="recaptcha"
                      @verify="onVerify"
                      sitekey="6LdbCKgZAAAAAAogKDYhUo5ksCGk_uSDHge2OeML"
                      :loadRecaptchaScript="true"
                  >
                  </vue-recaptcha>
                </CCol>
              </CRow>
            </template>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol col="12" sm="12" md="12" lg="12" xl="12" class=" text-right">
              <CButton @click="sendFeedBack" color="primary">Відправити</CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import {required, minLength, maxLength, email, requiredIf, sameAs} from 'vuelidate/lib/validators'
import {sendFeedBack} from "../../api/feedBack";
import {can} from "@/rules/permissions";

export default {
  name: "FeedBack",
  data: () => {
    return {
      title_FeedBack: '',
      comment_FeedBack: '',
      email: '',
      captcha: false,
      wasValidated: false,
      alerts: [],
    }
  },
  components: {VueRecaptcha},
  validations: {
    title_FeedBack: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(150)
    },
    comment_FeedBack: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(750)
    },
    email: {
      required: requiredIf(() => !can('provider_user') || can('bank_user')),
      email,
      minLength: minLength(3),
      maxLength: maxLength(750)
    },
    captcha: {
      required: requiredIf(() => !can('provider_user') || !can('bank_user')),
      sameAs: sameAs(() => true)
    }
  },
  methods: {
    can,
    onVerify() {
      this.captcha = true;
    },
    responseStatus(status) {
      if (status === 'done') return this.alerts.push({color: 'success', text: 'Зауваження відправлено менеджеру.'});
      self.alerts.push({
        color: 'danger',
        text: status ? status : 'Сталася помилка спробуйте пізніше.'
      })
    },
    async sendFeedBack() {
      let self = this;

      if (this.$v.$invalid) {
        this.wasValidated = true;
        return;
      }

      if (!can('provider_user') || !can('bank_user')) {
        if (process.env.NODE_ENV === "production") {
          if (!this.captcha) {
            self.alerts.push({
              color: 'danger',
              text: 'Підтвердіть, що ви не робот.'
            })
            return;
          }
        }
        await sendFeedBack({
          title: this.title_FeedBack,
          message: this.comment_FeedBack
        }, (status) => this.responseStatus(status));

      }

    }
  }
}
</script>
