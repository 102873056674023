import axios from "axios";
import {can} from "@/rules/permissions";

// Start Api
const ApiSendFeedBack = (url_data) => {
    try {
        let url = `/api/feedback`;
        if (url_data?.email || can('provider_user')) url = `/api/feedback?email="${url_data?.email || this.$store.state.login_info.email}"`;

        return axios.post(url, url_data.body);
    } catch (e) {
        return e.response.data
    }
}
// End Api

export const sendFeedBack = async (url_data, callback_func) => {
    let response, send_data = {
        body: {title: url_data.title, message: url_data.message},
        email: url_data?.email
    }
    response = await ApiSendFeedBack(send_data);

    if (response.status === 200) return callback_func({message: 'done'});
    callback_func(response);
};
